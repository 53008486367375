<template>
  <main class="max-w-7xl mx-auto ">
    <div class="lg:grid lg:grid-cols-12 lg:gap-x-5">
      <!-- Payment details -->
      <div class="space-y-6 lg:px-0 lg:col-span-12">
        <General />
        <Watermark />
        <TeamMembers />
        <SocialAccounts />
      </div>
    </div>
  </main>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";

import General from "@/screens/App/Settings/General";
import Watermark from "@/screens/App/Settings/Watermark";
import TeamMembers from "@/screens/App/Settings/TeamMembers";
import SocialAccounts from "@/screens/App/Settings/SocialAccounts";

export default {
  name: "SettingsPage",
  components: { TeamMembers, Watermark, General, SocialAccounts },
  computed: {
    ...mapState("auth", {
      currentUser: (state) => state.userData,
    }),
    ...mapState("team", {
      currentTeam: (state) => state.name,
      currentTeamId: (state) => state.id,
      users: (state) => state.users,
    }),
  },
};
</script>
